<template>
  <div class="MigrateSamplesStatusesModal">
    <span class="Title">{{ mixWB('Migrer prøve statusser') }}</span>

    <div class="Info">
      <div class="InfoRow">
        <span>Sagsnummer:</span>
        <span>{{ project.caseNumber }}</span>
      </div>
    </div>

    <div class="Info">
      <div class="InfoRow">
        <span>Projekt data:</span>
        <span>{{ currentScreeningLoaded ? 'Ja' : 'Nej' }}</span>
      </div>
      <div class="InfoRow">
        <span>Projekt typer:</span>
        <span>{{ currentScreeningTypesLoaded ? 'Ja' : 'Nej' }}</span>
      </div>
      <div class="InfoRow">
        <span>Projekt prøver:</span>
        <span>{{ currentScreeningSamplesLoaded ? 'Ja' : 'Nej' }}</span>
      </div>
      <div class="InfoRow">
        <span>Projekt PCB screninger:</span>
        <span>{{ currentScreeningPCBScreeningLoaded ? 'Ja' : 'Nej' }}</span>
      </div>
      <div class="InfoRow">
        <span>Projekt analyseresultater:</span>
        <span>{{ currentScreeningTestResultsLoaded ? 'Ja' : 'Nej' }}</span>
      </div>
    </div>

    <div class="Info">
      <div class="InfoRow">
        <span>Har standard prøver:</span>
        <span>{{ standardSamplesStatus.list.length ? 'Ja' : 'Nej' }}</span>
      </div>
      <div class="InfoRow">
        <span>Vurderede prøver:</span>
        <span>{{ assessedSamplesStatus.list.length ? 'Ja' : 'Nej' }}</span>
      </div>
      <div class="InfoRow">
        <span>Udskudte prøver:</span>
        <span>{{ postponedSamplesStatus.list.length ? 'Ja' : 'Nej' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { customLog } from '@/globals/javascript/_util/custom-log'
import { mapGetters } from 'vuex'

export default {
  name: 'MigrateSamplesStatusesModal',
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'currentScreeningData',
      'currentScreeningLoaded',
      'currentScreeningTypesLoaded',
      'currentScreeningSamplesLoaded',
      'currentScreeningPCBScreeningLoaded',
      'currentScreeningTestResultsLoaded',
      'standardSamplesStatus',
      'assessedSamplesStatus',
      'postponedSamplesStatus',
      'allResourcesLoaded',
      'currentScreeningLoaded',
      'currentScreeningTypesLoaded',
      'currentScreeningSamplesLoaded',
      'currentScreeningPCBScreeningLoaded',
      'currentScreeningTestResultsLoaded',
      'currentScreeningUnitsLoaded',
    ]),
  },
  methods: {
    async waitForData() {
      return new Promise((resolve) => {
        const wait = setInterval(() => {
          if (
            !this.allResourcesLoaded
            || !this.currentScreeningLoaded
            || !this.currentScreeningTypesLoaded
            || !this.currentScreeningSamplesLoaded
            || !this.currentScreeningPCBScreeningLoaded
            || !this.currentScreeningTestResultsLoaded
            || !this.currentScreeningUnitsLoaded
          ) {
            return
          }

          clearInterval(wait)
          resolve('ok')
        }, 100)
      })
    },
    async updateFlow() {
      try {
        // Reset project data
        this.$store.commit('resetCurrentScreening')

        // Get required project data
        this.$store.dispatch('getCurrentScreening', this.project.id)
        this.$store.dispatch('getScreeningTypes', this.project.id)
        this.$store.dispatch('getScreeningSamples', this.project.id)
        this.$store.dispatch('getScreeningPCBScreenings', this.project.id)
        this.$store.dispatch('getCurrentScreeningTestResults', this.project.id)
        this.$store.dispatch('getScreeningUnits', this.project.id)

        // Wait until all data is downloaded
        await this.waitForData()

        await this.$store.dispatch('forceUpdateScreeningCollections')

        this.$store.dispatch('migrateSamplesStatusses')

        this.$emit('pass-to-parent', { name: 'migration-resolved', value: 'success' })
      }
      catch (error) {
        this.$emit('pass-to-parent', { name: 'migration-resolved', value: 'failed' })
      }
    },
  },
  created() {
    customLog('migration', this.project.id)
    this.updateFlow()
  },
}
</script>

<style lang="stylus" scoped>
  .MigrateSamplesStatusesModal
    display block

  .Title
    modalTitle()

  .Info
    margin-bottom 20px

  .InfoRow
    display flex
    span
      &:first-child
        width 200px
</style>
