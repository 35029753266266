<template>
  <div class="MigrateAllProjects">
    <div class="InnerPage">
      <div class="Options">
        <Button
          :text="mixWB('Migrer prøve statusser')"
          :fullWidth="false"
          @button-click="onMigrateSamplesStatussesClick" />
        <Dropdown
          name="project-list"
          :value="selectedOption"
          :options="options"
          @on-update="onListOptionUpdate" />
      </div>
      <div class="List">
        <ProjectRow
          v-for="(project, index) in mutatedProjectsList"
          :key="project.id"
          :project="project"
          :index="index"
          :activeMigrateSamplesStatussesIndex="activeMigrateSamplesStatussesIndex"
          @migration-resolved="onMigrationResolved" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Buttons/Button.vue'
import { mapGetters } from 'vuex'
import ProjectRow from '@/components/MigrationItems/ProjectRow.vue'
import Dropdown from '@/components/FormElements/Dropdown.vue'

export default {
  name: 'MigrateAllProjects',
  data() {
    return {
      activeMigrateSamplesStatussesIndex: -1,
      selectedOption: 'moe',
      options: [
        {
          value: 'others',
          name: 'others',
        },
        {
          value: 'moe',
          name: 'moe',
        },
        {
          value: 'hol-1',
          name: 'hol-1',
        },
        {
          value: 'hol-2',
          name: 'hol-2',
        },
        {
          value: 'hol-3',
          name: 'hol-3',
        },
        {
          value: 'hol-4',
          name: 'hol-4',
        },
        {
          value: 'hol-5',
          name: 'hol-5',
        },
        {
          value: 'hol-6',
          name: 'hol-6',
        },
        {
          value: 'hol-7',
          name: 'hol-7',
        },
        {
          value: 'hol-8',
          name: 'hol-8',
        },
        {
          value: 'hol-9',
          name: 'hol-9',
        },
        {
          value: 'hol-10',
          name: 'hol-10',
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'allVersion2Projects',
    ]),
    mutatedProjectsList() {
      const startedProjects = this.allVersion2Projects.filter((x) => x.isStarted)

      if (this.selectedOption === 'others') {
        return startedProjects.filter(
          (x) => !['AOS5uXl3TqXMI9qfzHmY', 'bpZulvjI5VnFL9zVO0zb'].includes(x.accountID),
        )
      }

      if (this.selectedOption === 'moe') {
        return startedProjects.filter((x) => x.accountID === 'AOS5uXl3TqXMI9qfzHmY')
      }

      const holProjects = startedProjects.filter((x) => x.accountID === 'bpZulvjI5VnFL9zVO0zb')

      let start = 0
      let end = 0

      switch (this.selectedOption) {
      case 'hol-1':
        start = 0
        end = 50
        break
      case 'hol-2':
        start = 50
        end = 100
        break
      case 'hol-3':
        start = 100
        end = 150
        break
      case 'hol-4':
        start = 150
        end = 200
        break
      case 'hol-5':
        start = 200
        end = 250
        break
      case 'hol-6':
        start = 250
        end = 300
        break
      case 'hol-7':
        start = 300
        end = 350
        break
      case 'hol-8':
        start = 350
        end = 400
        break
      case 'hol-9':
        start = 400
        end = 450
        break
      case 'hol-10':
        start = 450
        end = 500
        break
      default:
        start = 0
        end = 0
      }

      return holProjects.slice(start, end)
    },
  },
  methods: {
    onListOptionUpdate({ value }) {
      this.selectedOption = value
    },
    onMigrateSamplesStatussesClick() {
      this.$store.dispatch('updateMigrationRunningStatus', true)
      this.activeMigrateSamplesStatussesIndex = 0
    },
    onMigrationResolved() {
      this.activeMigrateSamplesStatussesIndex += 1

      // Check if it was the last one
      if (this.activeMigrateSamplesStatussesIndex > this.mutatedProjectsList.length - 1) {
        this.$store.dispatch('updateMigrationRunningStatus', false)
        this.activeMigrateSamplesStatussesIndex = -1
        window.alert('Færdig')
      }
    },
  },
  components: { Button, ProjectRow, Dropdown },
  created() {
    if (!this.allVersion2Projects.length) {
      this.$store.dispatch('getAllVersion2Projects')
    }
  },
}
</script>

<style lang="stylus" scoped>
  .MigrateAllProjects
    page-wrap-outer-1()

  .InnerPage
    page-wrap-inner-2()

  .Options
    display flex
    gap 10px
    margin-bottom 20px

  .List
    border 1px solid $color_grey_lighter
    padding 20px

  >>> .Dropdown
    width 150px
</style>
