<template>
  <div class="ProjectRow">
    <div class="Info">
      <span class="Index">{{ index }}</span>
      <span class="CaseNumber">{{ project.caseNumber }}</span>
      <span class="Version">{{ project.appVersion }}</span>
      <span class="Id">{{ project.id }}</span>
      <span class="Text">{{ project.getStatus().text }}</span>
      <span :class="status.class">Status: {{ status.text }}</span>
    </div>

    <!-- Migrate samples status dialog -->
    <Dialog
      :isShowing="index === activeMigrateSamplesStatussesIndex"
      :useComponent="MigrateSamplesStatusesModal"
      :componentProps="{
        project,
      }"
      @migration-resolved="onMigrationResolved" />
  </div>
</template>

<script>
import Dialog from '@/components/Modals/Dialog.vue'
import MigrateSamplesStatusesModal from '@/components/MigrationItems/MigrateSamplesStatusesModal'

export default {
  name: 'ProjectRow',
  props: {
    project: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    activeMigrateSamplesStatussesIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      MigrateSamplesStatusesModal,
      migrationStatus: '',
    }
  },
  computed: {
    status() {
      if (this.migrationStatus === 'success') {
        return {
          class: 'Success',
          text: 'Success',
        }
      }
      if (this.migrationStatus === 'failed') {
        return {
          class: 'Failed',
          text: 'Failed',
        }
      }

      return {
        class: '',
        text: '-',
      }
    },
  },
  methods: {
    onMigrationResolved(result) {
      this.$emit('migration-resolved')

      this.migrationStatus = result
    },
  },
  components: { Dialog },
}
</script>

<style lang="stylus" scoped>
  .ProjectRow
    .Info
      display flex
      gap 10px
      .CaseNumber
        width 150px
      .Version
        width 80px
      .Index
        width 50px
      .Id
        width 200px
      .Text
        width 200px

  .Success
    color $color_primary

  .Failed
    color $color_error
</style>
